<template>
    <div class="MotorOptions">


        <div class="list w-100 py-2" v-if="motor && motor.id" :key="`motorpage${motor.id}`">
            <h4 class="h6 px-1 pt-1">Intervaly upozornění</h4>


            <table class="table table-striped">
                <tr>
                    <th>{{ $t("motor.options.servis-interval")}}</th>
                    <th>{{ $t("motor.options.mer-interval")}}</th>
                    <th>{{ $t("motor.options.maz-interval")}}</th>
                    <th>{{ $t("motor.options.hours_daily")}}</th>

                </tr>

                <tr >

                    <th>
                        <input id="inputName2" type="number" min="1" max="2000" class="form-control form-control-sm"
                                @keypress="onlyNumber" v-model.trim="motor.intervalS" :placeholder="defaults.intervalS"/>
                    </th>
                    <th>
                        <input id="inputName3" type="number" min="1" max="2000" class="form-control form-control-sm"
                                @keypress="onlyNumber"  v-model.trim="motor.intervalM" :placeholder="defaults.intervalM"/>
                    </th>
                    <th>
                        <input id="inputName4" type="number" min="1" max="2000" class="form-control form-control-sm" :disabled="!motor.type_need_lubrication"
                                @keypress="onlyNumber"  v-model.trim="motor.intervalL" :placeholder="defaults.intervalL"/>
                    </th>
                    <th>
                        <input id="inputName5" type="number" min="1" max="2000" class="form-control form-control-sm"
                                @keypress="onlyNumber" v-model.trim="motor.hours_daily" />
                    </th>

                </tr>
            </table>


            <div class="row">
                <div class="col-md-3">

                        <div class="form-check form-check-inline mt-4 ml-3">
                            <input v-model.trim="motor.enable_notify_email" type="checkbox" class="form-check-input" id="exampleCheck1">
                            <label class="form-check-label" for="exampleCheck1"> {{ $t('motor.options.enable_email_notify')}}  </label>
                        </div>


                </div>
                <div class="col-md-2">
                    <label>{{$t('motor.options.email_for_notify')}}</label>
                    <input id="inputName6" type="email" class="form-control form-control-sm" v-model.trim="motor.notify_email" :disabled="!motor.enable_notify_email"/>
                </div>

            </div>

            <div class="row">

              <div class="col-md-12"><strong>{{ $t('m.PEngineSettings.Upozorneni')}}</strong></div>
                 <div class="col-md-12">
                   <div class="form-check form-check-inline mt-1 ml-3">
                              <input v-model.trim="motor.enableMeasNtf" type="checkbox" class="form-check-input" id="exampleCheck3">
                              <label class="form-check-label" for="exampleCheck3"> {{ $t('m.PEngineSettings.NaPreventivniMereni')}}  </label>
                  </div>
                 </div>
                 <div class="col-md-12">
                   <div class="form-check form-check-inline mt-1 ml-3">
                              <input v-model.trim="motor.enableLubrNtf" type="checkbox" class="form-check-input" id="exampleCheck4">
                              <label class="form-check-label" for="exampleCheck4"> {{ $t('m.PEngineSettings.NaDomazani')}}  </label>
                   </div>
                 </div>
            </div>

          <div class="row">

          <div class="col-md-4">
            <label style="color:transparent"> &nbsp; .</label><div class="clearfix"></div>

            <button class="btn btn-sm btn-outline-dark mr-2" @click="resetIntervalValues">{{$t('form.reset-use-default')}}</button>

            <button class="btn btn-sm btn-success mr-1" @click="saveIntervals()">{{$t('form.save-changes')}}</button>
          </div>
  </div>

            <hr>
                <div style="width: 400px;">
                             <h4 class="h6 px-1 pt-1">{{ $t('motor.options.alarm-settings') }}</h4>

                            <table class="table alarms">
                                <tr >
                                    <th class="h" rowspan="1"></th>
                                 <!--   <th colspan="2" class="text-center vyborne">{{$t("admin.alarm.vyborne")}}</th>
                                    <th colspan="2" class="text-center dostatecne">{{$t("admin.alarm.dostatecne")}}</th>-->
                                    <th colspan="1" class="text-center spatne">{{$t("admin.alarm.nedostatecne")}} </th>

                                </tr>
                  <!--              <tr>

                                      <th class="vyborne">od</th><th class="vyborne">do</th>
                                    <th  class="dostatecne">od</th><th class="dostatecne">do</th>
                                    <th class="spatne"></th>
                                  <th class="spatne">do</th>
                                </tr>-->
                                <tr >
                                    <td class="h"> <b>Vibrace</b></td>
                <!--
                                    <td class="vyborne"><div class="fg"><input v-model="alarms.v1min" type="number"/>mm/ss</div></td>
                                    <td class="vyborne"><div class="fg"><input v-model="alarms.v1max" type="number" :class="errors.v1max ? 'error':''"/>mm/ss</div></td>

                                    <td class="dostatecne"><div class="fg"><input v-model="alarms.v2min" readonly  />mm/ss</div></td>
                                    <td class="dostatecne"><div class="fg"><input v-model="alarms.v2max"  type="number" :class="errors.v2max ? 'error':''"/>mm/ss</div></td>
                -->
                                    <td class="spatne"><div class="fg">
                                      <input @keypress="onlyNumber" v-model.number="alarms.v3max" :placeholder="defaultOption.v3max" />mm/ss</div></td>
                               <!--     <td class="spatne"><div class="fg"><input v-model="alarms.v3max"  type="number" :class="errors.v3max ? 'error':''" />mm/ss</div></td> -->
                                </tr>
                                <tr>
                                    <td  class="h" > <b>Teplota </b> </td>
                <!--
                                    <td class="vyborne"><div class="fg"><input v-model="alarms.t1min"  type="number" />&deg;C</div></td>
                                    <td class="vyborne"><div class="fg"><input v-model="alarms.t1max"  type="number" :class="errors.t1max ? 'error':''" />&deg;C</div></td>

                                    <td class="dostatecne"><div class="fg"><input v-model="alarms.t2min" readonly  />&deg;C</div></td>
                                    <td class="dostatecne"><div class="fg"><input v-model="alarms.t2max"  type="number" :class="errors.t2max ? 'error':''" />&deg;C</div></td>
                -->
                                    <td class="spatne"><div class="fg"><input @keypress="onlyNumber"  v-model.number="alarms.t3max"  :placeholder="defaultTermOption.t3max" />&deg;C</div></td>
                           <!--         <td class="spatne"><div class="fg"><input v-model="alarms.t3max"  type="number" :class="errors.t3max ? 'error':''" />&deg;C</div></td>-->
                                </tr>
                            </table>
                            <div class="mt-2 text-right">
                              <button class="btn btn-sm btn-outline-dark mr-2" @click="resetAlarmValues">{{$t('form.reset-use-default')}}</button>
                              <button class="btn btn-sm btn-success " @click="saveAlarms()" :disabled="cokolivSpatne">{{$t('form.save-changes')}}</button>

                            </div>

                </div>

        </div><div v-else>loading...</div>

        </div>

</template>
<style scoped lang="scss">
    .alarms{
        td{
            line-height:33px;

            .fg{
                display: flex;
                input{
                    width:80%;
                    margin-right: 3px;

                    &.error{
                        border-color: #ff2525;
                        padding:0px 5px;
                        background-color: #ffccac;
                    }
                }
            }

        }
        .h{
            background: lightgray;
        }
        input{


        }
    }
</style>

<script>
    const axios = require("axios");
    axios.defaults.withCredentials = true

    import axiosApiInstance from "@/utils/api";

    export default {
        props: {
        },
        data(){
            return{
                motor:[],
                alarms:{t3min:'', v3min:'', v3max:'', t3max: ''},
                errors:[],
              defaultOption:{},
              defaultTermOption:{},
              defaults:{}


            }
        },
        watch:{
        },
        computed:{
            alarmsc(){
              console.log()
                if (!this.motor || !this.motor.alarms) return []
                return this.motor.alarms
            },
            cokolivSpatne(){
                for(var i in this.errors){
                    console.log('jesi je ',i,  this.errors[i])
                    if ( this.errors[i] ) return true
                }
                return false
            }
        },
        mounted() {
            //console.log('mounted Options')
            this.motor = this.$parent.motor
            this.$parent.$on('motorUpdated', data => {
                //console.log('upd', this.motor.id)
                if (!this.motor.id) this.motor = data
              this.alarms = data.alarms
              this.getDefaultAlarms()
              this.loadDefaultIntervals()
            })

          this.alarms = this.motor.alarms
        },
        methods:{

          onlyNumber ($event) {
            //console.log($event.keyCode); //keyCodes value
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
              $event.preventDefault();
            }
          },



          resetIntervalValues(){
            this.motor.intervalS=''
            this.motor.intervalM=''
            this.motor.intervalL=''
            this.motor.hours_daily=''
            this.saveIntervals()
            this.loadDefaultIntervals()
          },

          resetAlarmValues(){
            this.alarms.t3max=''
            this.alarms.t3min=''
            this.alarms.v3min=''
            this.alarms.v3max=''
            this.saveAlarms()
            this.getDefaultAlarms()
          },

            saveIntervals(){
                var d={
                    source: 'web',
                    intervalS: this.motor.intervalS,
                    intervalL: this.motor.intervalL,
                    hours_daily: this.motor.hours_daily,
                    intervalM: this.motor.intervalM,
                    notify_email: this.motor.notify_email,
                    enable_notify_email: this.motor.enable_notify_email,
                    enableMeasNtf:this.motor.enableMeasNtf,
                    enableLubrNtf:this.motor.enableLubrNtf
                }
                axios.put(process.env.VUE_APP_API_ROOT_URL + `/motors/${this.motor.token}`, d)
                    .then(response => {
                        if (response.status == 200) {
                            this.$toasted.show( this.$t('motor.options.update-ok'),{ fullWidth: true,iconPack: 'fontawesome', type: 'success' ,duration : 3000})
                        }
                    })

            },

            saveAlarms(){
                axios.put(process.env.VUE_APP_API_ROOT_URL + `/motors/${this.motor.token}`, {
                  source: 'web',
                  alarms: {
                        t1min: this.alarms.t1min,
                        t1max: this.alarms.t1max,
                        t2min: this.alarms.t2min,
                        t2max: this.alarms.t2max,
                        t3min: this.alarms.t3max,
                        t3max: this.alarms.t3max,
                        v1min: this.alarms.v1min,
                        v1max: this.alarms.v1max,
                        v2min: this.alarms.v2min,
                        v2max: this.alarms.v2max,
                        v3min: this.alarms.v3max,
                        v3max: this.alarms.v3max
                    }})
                    .then(response => {
                        if (response.status == 200) {
                            this.$toasted.show( this.$t('motor.options.update-alarms-ok'),{ fullWidth: true,iconPack: 'fontawesome', type: 'success' ,duration : 3000})
                            this.motor.alarms = this.alarms
                        }
                    })
            },

          loadDefaultIntervals(){

            axiosApiInstance.get(`/engine_types?${new Date().getTime()}`)  .then(response => {
              const types= response.data
              for (var i in types){
                if (types[i].title=='Default') this.defaults = types[i]
              }

              for (i in types){
                if (types[i].title == this.motor.productline) this.defaults = types[i]
              }

            })

          },


          getDefaultAlarms(){
            axios.get(process.env.VUE_APP_API_ROOT_URL + "/alarms?"+new Date().getTime())
                    .then(response => {
                        if (response.status == 200) {
                            console.log('OK')
                            const settings = response.data

                          let mindiff=100000
                          const mh = this.motor.axisheight
                            for(let i=0; i<settings.length; i++){

                              if (settings[i].axisheight>0) {
                                let diff = Math.abs(mh - settings[i].axisheight)
                                if (diff < mindiff) {
                                  mindiff = diff
                                  this.defaultOption = settings[i]
                                }
                              }

                              if (settings[i].pole  && settings[i].pole == this.motor.pole) {
                                  this.defaultTermOption = settings[i]
                              }

                            }

                        }
                    }).catch((err)=>{
                        console.error("problem cteni alarms", err)
                 })
          }
        }
    };
</script>
